import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Form, Input, InputNumber, message, Select, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import GoogleAutoComplete from 'react-google-autocomplete';
import {
  validateStorageZipCode,
  createStorageDeliveryOrder,
  createDeliveryOrderCheckoutSession
} from '../../../../../data/API';
import { setExtraWork, setOrderDeliverDetails } from '../../../store/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getMyData } from '../../../../../utils/token';
import * as _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

const DeliveryDetails = ({ boxPrices, deliveryWindows, extraWork, customerInfo }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const customer = getMyData();

  const { deliveryOrder } = useSelector((state) => state.storage);

  useEffect(() => {
    if (deliveryOrder && deliveryOrder?.deliveryDetails) {
      form.setFieldsValue({
        delivery_date: deliveryOrder?.deliveryDetails?.delivery_date
          ? dayjs(deliveryOrder?.deliveryDetails?.delivery_date)
          : '',
        delivery_window_1_id: deliveryOrder?.deliveryDetails?.delivery_window_1_id
          ? deliveryOrder?.deliveryDetails?.delivery_window_1_id
          : '',
        delivery_window_2_id: deliveryOrder?.deliveryDetails?.delivery_window_2_id
          ? deliveryOrder?.deliveryDetails?.delivery_window_2_id
          : '',
        apt_number: deliveryOrder?.deliveryDetails?.apt_number
          ? deliveryOrder?.deliveryDetails?.apt_number
          : '',
        zipCode: deliveryOrder?.deliveryDetails?.zipCode
          ? deliveryOrder?.deliveryDetails?.zipCode
          : '',
        extra_work_id: deliveryOrder?.deliveryDetails?.extra_work_id
          ? deliveryOrder?.deliveryDetails?.extra_work_id
          : '',
        description: deliveryOrder?.deliveryDetails?.description
          ? deliveryOrder?.deliveryDetails?.description
          : ''
      });

      setDeliveryAddress(deliveryOrder?.deliveryDetails?.delivery_address);
    }
  }, [deliveryOrder]);

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [timingOne, setTimingOne] = useState(null);
  const [timingTwo, setTimingTwo] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [deliveryAddressError, setDeliveryAddressError] = useState(null);

  const handleTimingOneChange = (evt) => {
    setTimingOne(evt);

    if (evt == timingTwo) {
      setTimingTwo(null);
    }
    handleDeliveryDetailsRedux('delivery_window_1_id', evt);
  };

  const handleTimingTwoChange = (evt) => {
    setTimingTwo(evt);
    handleDeliveryDetailsRedux('delivery_window_2_id', evt);
  };

  const disableDate = (current) => {
    var todayDate = moment();
    // var currentDayNumber = new Date(current.$d).getDay();

    return (
      // currentDayNumber == 0 ||
      // currentDayNumber == 6 ||
      todayDate >= current.$d ||
      current.$d == moment(todayDate).add(1, 'days') ||
      moment(todayDate).add(1, 'days').format('MM-DD-YYYY') ==
      moment(current.$d).format('MM-DD-YYYY')
    );
  };

  const onAddresschange = (e) => {
    if (deliveryAddress && e.target.value == 0) {
      setDeliveryAddress('');
      setDeliveryAddressError('Please Select Delivery Address');
    }
    handleDeliveryDetailsRedux('delivery_address', e.target.value);
  };

  const setAddress = async (place) => {
    handleDeliveryDetailsRedux('delivery_address', place?.formatted_address);
    let postal, country, city, state;
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes('postal_code')) postal = address_element.long_name;
      if (address_element.types.includes('country')) country = address_element.short_name;
      if (address_element.types.includes('locality')) city = address_element.long_name;
      if (address_element.types.includes('administrative_area_level_1'))
        state = address_element.long_name;
    });

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    let zipCodeRes = await validateStorageZipCode({ deliveryZipCode: postal ? postal : '781006' });

    if (zipCodeRes?.data?.success && zipCodeRes?.data?.data?.serviceExist) {
      setZipCodeError(null);
      setDeliveryAddress(place?.formatted_address);
    } else {
      setZipCodeError('Service not available in this area.');
    }
  };

  const onStairsSelected = async (data) => {
    dispatch(setExtraWork(data));
    handleDeliveryDetailsRedux('extra_work_id', data);
  };

  const changeDate = (date, dateString) => {
    // console.log(date, dateString);
    handleDeliveryDetailsRedux('delivery_date', date);
  };

  const handleDeliveryDetailsRedux = (type, e) => {
    switch (type) {
      case 'delivery_date':
        form.setFieldsValue({
          delivery_date: e
        });
        break;
      case 'delivery_window_1_id':
        form.setFieldsValue({
          delivery_window_1_id: e
        });
        break;
      case 'delivery_window_2_id':
        form.setFieldsValue({
          delivery_window_2_id: e
        });
        break;
      case 'delivery_address':
        form.setFieldsValue({
          delivery_address: e
        });
        break;
      case 'extra_work_id':
        form.setFieldsValue({
          extra_work_id: e
        });
        break;
      case 'zipCode':
        form.setFieldsValue({
          zipCode: e
        });
        break;
      case 'apt_number':
        form.setFieldsValue({
          apt_number: e
        });
        break;
      case 'description':
        form.setFieldsValue({
          description: e.target.value
        });
        break;
    }

    dispatch(setOrderDeliverDetails(form.getFieldsValue()));
  };

  const onSubmit = async () => {
    try {
      setIsFormSubmitted(true)
      if (!deliveryAddress) {
        setDeliveryAddressError('Please select delivery address');
      }

      let formValues = await form.validateFields();

      if (formValues?.errorFields?.length > 0) {
        setIsFormSubmitted(false);
        return;
      }

      if (zipCodeError) {
        setIsFormSubmitted(false);
        return;
      }

      if (!deliveryAddress) {
        setIsFormSubmitted(false);
        setDeliveryAddressError('Please select delivery address');
        return;
      }

      let data = {
        delivery_date: moment(formValues.delivery_date?.$d).format('YYYY-MM-DD'),
        delivery_window_1_id: formValues?.delivery_window_1_id,
        delivery_window_2_id: formValues?.delivery_window_2_id,
        delivery_address: deliveryAddress,
        extra_work_id: formValues?.extra_work_id,
        zipCode: formValues?.zipCode
      };

      if (formValues?.apt_number) {
        data['apt_number'] = formValues.apt_number;
      }
      if (formValues?.description) {
        data['description'] = formValues.description;
      }

      dispatch(setOrderDeliverDetails(data));

      let extraCost = 0;

      let extraWorkObj = _.find(extraWork, { id: formValues?.extra_work_id });

      if (extraWorkObj) {
        extraCost = Number(extraWorkObj.price);
      }

      const payload = {
        total_amount:
          (boxPrices?.length > 0 ? Number(boxPrices[1]?.price) : 29) + (extraCost ? extraCost : 0),
        customer_id: customer.id,
        order_date: new Date(),
        ...deliveryOrder
      };

      let response = await createStorageDeliveryOrder(payload);

      if (response?.data?.success) {
        let sessionPayload = {
          order_id: response.data.data.id,
          amount: payload.total_amount,
          discount: 0,
          payment: {
            billing_details: {
              email: customer.email
            }
          }
        };

        let sessionRes = await createDeliveryOrderCheckoutSession(sessionPayload);

        if (sessionRes?.data?.success) {
          const stripe = await stripePromise;

          const result = await stripe.redirectToCheckout({
            sessionId: sessionRes.data.data.session
          });

          setIsFormSubmitted(false);
        } else {
          message.error('Unable to place order, Please try again...!');
          setIsFormSubmitted(false);
        }
      } else {
        setIsFormSubmitted(false);
        message.error('Unable to place order, Please try again');
      }
    } catch (err) {
      setIsFormSubmitted(false);
      return message.error('Oops an error occured while placing order, Please try again later');
    }
  };

  return (
    <div>
      <div className="steps bg-white-100" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Delivery Details
        </h2>
        <Card bordered={false} className="w-100 px-3 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_date"
                    label="Delivery Date"
                    rules={[{ required: true, message: 'Please choose a delivery date' }]}>
                    <DatePicker
                      className="w-100"
                      size="large"
                      format="MM-DD-YYYY"
                      disabledDate={disableDate}
                      onChange={changeDate}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_1_id"
                    label="Delivery Window 1"
                    rules={[{ required: true, message: 'Please choose a delivery window 1' }]}>
                    <Select
                      size="large"
                      placeholder="Select a time slot"
                      value={timingOne}
                      onChange={handleTimingOneChange}>
                      {deliveryWindows?.map((options, index) => {
                        return (
                          <Select.Option
                            key={`timing_one_${index}`}
                            hidden={options?.id == timingTwo}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_2_id"
                    label="Delivery Window 2"
                    rules={[{ required: true, message: 'Please choose a delivery window 2' }]}>
                    <Select
                      size="large"
                      placeholder="Select a time slot"
                      onChange={handleTimingTwoChange}
                      value={timingTwo}
                      disabled={!timingOne}>
                      {deliveryWindows?.map((options, index) => {
                        return (
                          <Select.Option
                            hidden={options?.id == timingOne}
                            key={`timing_two_${index}`}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className="mandatory-field"
                    name="delivery_address"
                    label="Delivery Address">
                    <GoogleAutoComplete
                      className={
                        deliveryAddressError && !deliveryAddress
                          ? 'form-control delivery-address-field-error '
                          : 'form-control'
                      }
                      apiKey={apiKey}
                      onPlaceSelected={(place) => {
                        setAddress(place);
                      }}
                      onChange={(e) => {
                        onAddresschange(e);
                      }}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                      defaultValue={deliveryAddress}
                    />
                    <p className="mb-0 ant-form-item-explain-error">
                      {zipCodeError ? zipCodeError : null}
                    </p>
                    <p className="mb-0 ant-form-item-explain-error">
                      {deliveryAddressError && !deliveryAddress ? deliveryAddressError : ''}
                    </p>
                    <p className="mb-0 text-success">
                      {!zipCodeError && deliveryAddress ? 'Services available in this area' : null}
                    </p>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="apt_number" label="Apt." rules={[{ required: false }]}>
                    <InputNumber
                      className="w-100"
                      placeholder="Apt."
                      size="large"
                      controls={false}
                      onChange={(e) => handleDeliveryDetailsRedux('apt_number', e)}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="zipCode" label="ZipCode" rules={[{ required: false }]}>
                    <Input
                      size="large"
                      placeholder="Zip Code"
                      onChange={(e) => handleDeliveryDetailsRedux('zipCode', e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="extra_work_id"
                    label="Do you have stairs or an elevator?"
                    rules={[{ required: true, message: 'Please select an option from below' }]}>
                    <Select size="large" onChange={onStairsSelected}>
                      {extraWork?.map((obj, index) => {
                        return (
                          <Select.Option
                            key={`extarwork_${index}`}
                            value={obj?.id}>{`${obj.title} ($${obj.price})`}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="description"
                    label="Is there anything else we should know?"
                    rules={[{ required: false }]}>
                    <TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                      onChange={(e) => handleDeliveryDetailsRedux('description', e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <Row className="justify-content-end py-3">
        <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <Button
              className="btn-next-step"
              icon={<ArrowRightOutlined />}
              loading={isFormSubmitted}
              onClick={onSubmit}
              size={'large'}
              block>
              {!isFormSubmitted ? 'Submit Order' : 'Redirecting..'}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeliveryDetails;

DeliveryDetails.propTypes = {
  deliveryWindows: PropTypes.any,
  extraWork: PropTypes.any,
  customerInfo: PropTypes.any,
  boxPrices: PropTypes.any
};
