import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Skeleton, message, Popover } from 'antd';
import './style.css';
import {
  setRentalPeriod,
  setCategory,
  setPackage,
  setStepCount,
  resetReduxStore,
  setPackageIndex
} from '../store/actionCreators';
import { rentalsPeriods, getBoxPackages, addtoCart, clearCart } from '../../../data/API';
import moment from 'moment';
const content = (
  <div className="box-popover">
    <p>A recycling bag(s) is included in your order at the time of delivery. On your pickup date, along with your moving boxes we will pickup and recycle your used packing paper, honeycomb wrap, and bubble wrap. Just place everything in the provided bag. Cardboard NOT accepted</p>
  </div>
);
export default function index(props) {
  const dispatch = useDispatch();
  const session = ReactSession.get('session');

  const [rentalPeriods, setRentalPeriods] = useState([]);
  const [boxPackages, setBoxPackages] = useState([]);

  const selectedRentalPeriod = useSelector(
    (state) => state.package.choosePackage.selectedRentalPeriod.period
  );
  const { category, subCategory, selectedPackage } = useSelector(
    (state) => state.package.choosePackage
  );
  const currentStep = useSelector((state) => state.package.currentStep);
  const isChangePackage = useSelector((state) => state.package.isChangePackage);
  const packageIndex = useSelector((state) => state.package.choosePackage.packageIndex);

  const { isParentNextStepClicked, setParentNextStepClicked, stepRef } = props;

  const nextStep = () => {
    if (selectedPackage?.id) {
      dispatch(setStepCount(currentStep + 1));
    } else {
      message.error('Please select a package from the options below..!');
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 1) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          nextStep();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  const getRentalPeriods = async () => {
    let rentalPeriodsRes = await rentalsPeriods();

    if (rentalPeriodsRes?.data) {
      if (!selectedRentalPeriod) rentalPeriodSelected(rentalPeriodsRes?.data.results[1]);
      setRentalPeriods(rentalPeriodsRes?.data.results);
    } else {
      message.error('error fetching rental periods');
    }
  };

  const getPackages = async () => {
    if (category && subCategory && selectedRentalPeriod) {
      let res = await getBoxPackages(category, subCategory, selectedRentalPeriod);
      if (res?.data?.results) {
        res.data.results.sort((a, b) => a.total_boxes - b.total_boxes).forEach((obj) => {
          obj.description = obj.description
            .replaceAll('<br />', ',')
            .replaceAll('&nbsp;', '')
            .replaceAll('&amp;', '&')
            .replaceAll(/<\/?[^>]+(>|$)/g, '')
            .split(',');
        });

        setBoxPackages(res.data.results);
      }
    }
  };

  // Init Invocations
  React.useEffect(() => {
    getRentalPeriods();
  }, []);

  useEffect(() => {
    getPackages();
  }, [category, selectedRentalPeriod]);

  useEffect(() => {
    if (packageIndex !== null) {
      packageSelected(boxPackages[packageIndex], packageIndex);
    }
  }, [boxPackages]);

  const rentalPeriodSelected = async (obj) => {
    if (selectedRentalPeriod) {
      // await clearCart(session);
      // dispatch(resetReduxStore());
    }

    let rentalObj = {
      period: obj.period,
      id: obj.id
    };
    dispatch(setRentalPeriod(rentalObj));
  };

  const changeCategory = (category) => {
    dispatch(setCategory(category));
  };

  const packageSelected = async (obj, index) => {
    let data = {
      product: obj.id,
      quantity: obj.quantity,
      session: session,
      cart_main_category: 2,
      cart_sub_category: obj.product_sub_category,
      rental: obj.period_id
    };
    let res = await addtoCart(data);

    if (res?.data?.id) {
      dispatch(setPackageIndex(index));
      dispatch(setPackage(obj));
    } else message.error('Something went wrong while adding to cart. Please try again..!');
  };

  const resolveImagePath = (title) => {
    title = title.replaceAll(' ', '');
    return 'img/PackageImages/' + title + '.png';
  };

  return (
    <>
      {/* Step 1 */}
      <div>
        <div className="steps bg-white" id="steps">
          <h2 className="text-white text-center py-2 fs-20 steps-title shopping-cart-header">
            Step 1: Rental Period and Box Package
          </h2>
          <div className="row pl-2 pr-2">
            <div className="col-12 pb-3 pt-3">
              <b>
                <p className="fs-20 text-center ms-xl-1 mt-0 mb-0">Select Rental Period</p>
              </b>
            </div>
            <div className="col-12 mt-2">
              <div className="justify-content-between rent-periods">
                <div className="row">
                  {rentalPeriods.map((obj, index) => {
                    return (
                      <div
                        className="col-lg-2  m-0 pl-2 pr-2 rent-period-parent"
                        key={'period_' + index}>
                        <button
                          key={obj.id}
                          className={
                            obj.period == selectedRentalPeriod
                              ? 'btn mt-0  br-5 mt-0  active rent-period '
                              : 'btn mt-0  br-5 mt-0 rent-period '
                          }
                          onClick={() => {
                            rentalPeriodSelected(obj);
                          }}>
                          {obj.period}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-12 m-auto">
              <section className="show_btn pb-0 bg-none pt-0" id="show_btn">
                <div className="container">
                  <div className="row">
                    <div className="col-12 mt-5">
                      <h2 className="fs-20">Select Package Type</h2>
                    </div>
                    <div className="col-md-12">
                      <button
                        className={
                          category == 'Home'
                            ? `rounded-right active homebtn br-5 br-r-0 border-0 fs-15 fw-500`
                            : `rounded-right homebtn br-5  bg-grey br-r-0 border-0 fs-15 fw-500`
                        }
                        id="homebtn"
                        value="Home"
                        onClick={() => {
                          changeCategory('Home');
                        }}>
                        HOME
                      </button>
                      <button
                        className={
                          category == 'Office'
                            ? `rounded-left  officebtn active br-5  br-l-0 border-0 fs-15 fw-500`
                            : `rounded-left  officebtn br-5 bg-grey br-l-0 border-0 fs-15 fw-500`
                        }
                        id="officebtn"
                        value="Office"
                        onClick={() => {
                          changeCategory('Office');
                        }}>
                        OFFICE
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="container px-5 my-4">
              {boxPackages?.length == 0 ? (
                <div className="row py-4 pt-0">
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row py-4 pt-0">
                {boxPackages.map((obj, index) => {
                  return (
                    <div key={obj.id} className="col-md-4 p-0 pkg d-flex z-index-0">
                      <div className="card mt-5 w-100">
                        <div className="card-header bg-danger">
                          <div className="text-center">
                            <h3 className="fs-22 text-white mb-0">{obj.title}</h3>
                            {/* <p className=" fs-15 text-white mb-0">{obj.total_boxes} Boxes</p> */}
                          </div>
                        </div>
                        <div className="text-center pt-3 pb-3">
                          {/* <img src={"img/PackageImages/" + obj.title + ".png"} className="img-fluid" /> */}
                          <img src={obj.description_image} className="img-fluid" />
                        </div>
                        <div className="card-body">
                          <div className="text-center">
                            <h2 className="fs-30">
                              <span className="top fs-14 crs">$</span>
                              {obj.price}
                              <span className="middle fs-14">/ {obj.period}</span>
                            </h2>
                            <div className="mt-3">
                              <div className="text-left ml-4 mr-3">
                                {obj.description.map((desc, index) => {
                                  return (
                                    <p className="mb-0 fs-14" key={index}>
                                      <i className="fas fa-check color-primary"></i>{" "}
                                      {desc}
                                    </p>
                                  );
                                })}
                                <p className="mb-0 fs-14">
                                  <i className="fas fa-check color-primary"></i>{" "}
                                  Free Recycling of Used Packing Materials {" "}
                                  <Popover content={content}>
                                    <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                                  </Popover>
                                </p>
                                {/* {obj?.stock > 0 ? (
                                  <p className="mb-0 fs-14 py-3">
                                    <span className="color-secondary font-bold">
                                      Available Stock :{' '}
                                    </span>
                                    {obj?.stock}
                                  </p>
                                ) : ""} */}
                                {obj?.stock == 0 && obj?.expected_stock_date ? (
                                  <p className="mb-0 fs-14 py-3 " >
                                    <span className="color-secondary font-bold">
                                      Available From:{' '}
                                    </span>
                                    {moment(obj?.expected_stock_date).format("DD-MM-YYYY")}
                                  </p>
                                ) : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer card-button-footer">
                          <div className="d-flex justify-content-center mt-2 mb-3">
                            <div className="radio-toolbar align-content-center">
                              {obj?.stock > 0 ? (selectedPackage.id == obj.id ? (
                                <label className="btn  br-5 fs-15 btn-selected width-90p border-0  fw-500">
                                  {'Selected'}
                                </label>
                              ) : (
                                <label
                                  className="btn btn-danger br-5 fs-15  width-90p border-0 bg-theme-secondary fw-500"
                                  htmlFor="add_package"
                                  onClick={() => {
                                    packageSelected(obj, index);
                                  }}>
                                  {'Select Package'}
                                </label>
                              )) : (
                                <Button type='primary' danger ghost disabled block>
                                  OUT OF STOCK
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-10 p-4">
          <div className="col px-1"></div>
          <div className="col px-1 d-flex justify-content-end align-items-center">
            <Button
              className="btn-next-step"
              disabled={!selectedPackage.id}
              icon={<ArrowRightOutlined />}
              size={'large'}
              onClick={nextStep}>
              Next Step
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
