import React, { useState, useRef, useEffect } from 'react';
import ZipCode from '../Home/ZipModal';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';
import { getBoxPackages, getTotalCart, clearCart, checkZipCode } from '../../data/API';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSession } from 'react-client-session';
import PropTypes from 'prop-types';
import { setCategory } from '../BoxPackages/store/actionCreators';
import toast from 'react-hot-toast';
import * as $ from 'jquery'
import { Popover } from "antd";
import moment from "moment/moment";

const content = (
    <div className="box-popover">
      <p>A recycling bag(s) is included in your order at the time of delivery. On your pickup date, along with your moving boxes we will pickup and recycle your used packing paper, honeycomb wrap, and bubble wrap. Just place everything in the provided bag. Cardboard NOT accepted</p>
    </div>
);
import {Helmet} from "react-helmet";


export default function Index(props) {
  // props.setFooteroffice(true);
  // props.setshowHideFooter(false);
  const [mainloader, setMainloader] = React.useState(false);
  const [, forceUpdate] = useState();
  const [errorMsg, setMsg] = React.useState('');
  const [isError, setError] = React.useState(false);
  const navigate = useNavigate();
  const [openZipModal, setZipModal] = React.useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [values, setValues] = React.useState({
    pickup_zipcode: '',
    delivery_zipcode: ''
  });
  const [product, setProduct] = useState([]);
  const [toggleModal, setToggleModal] = useState(true);

  const [rental, setRental] = useState(null);
  const category = useSelector(state => state.boxPackage.category);
  const dispatch = useDispatch();

  // const [active, setActive] = React.useState('Home');
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    const item = {
      value: true,
      expiry: new Date().getTime()
    };
    // console.log(item.expiry);
    localStorage.setItem('zipcode', JSON.stringify(item));
  };

  window.addEventListener('scroll', (event) => {
    setAnimation();
  });

  const setAnimation = () => {
    setTimeout(() => {
      $(".aos-init").addClass("aos-animate")
    }, 0);
  }
  setAnimation();

  const resolveImagePath = (title) => {
    title = title.replaceAll(" ", "");
    return "img/PackageImages/" + title + ".png"
  }

  const handleZipCode = (e) => {
    e.preventDefault();
    setMainloader(true);
    if (validator.current.allValid()) {
      e.preventDefault();

      let obj = {
        pickup_zipcode: values.pickup_zipcode,
        delivery_zipcode: values.delivery_zipcode
      };
      checkZipCode(obj)
        .then((res) => {
          if (res?.data?.success) {
            navigate('/packages');
            props.setShowHideHeader(false);
            props.setshowHideFooter(false);
            props.setshowHideinnerFooter(true);
          } else {
            setError(true);
            setMsg(res?.data?.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      e.preventDefault();
      validator.current.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const setnewcategory = (newcat) => {
    dispatch(setCategory(newcat))
  };

  const hideZipM = () => {
    setZipModal(false);
  };

  const showZipModal = () => {
    setZipModal(true);
  };


  //Trigger Category Change
  useEffect(() => {
    let mounted = true
    props.setshowZipModal(true);
    getBoxPackages(category, 'Box Packages', '1')
      .then((res) => {
        if (mounted) {
          res.data.results.sort((a, b) => a.total_boxes - b.total_boxes).forEach((obj) => {
            obj.description = obj.description
              .replaceAll('<br />', ',')
              .replaceAll('&nbsp;', '')
              .replaceAll('&amp;', '&')
              .replaceAll(/<\/?[^>]+(>|$)/g, '')
              .split(',');
          });
          setProduct(res?.data);
        }
      })
      .catch((e) => {
        toast.error(`Failed to fetch data for ${category}`, {
          style: {
            border: '1px solid red',
            padding: '16px',
            color: 'red',
          },
          iconTheme: {
            primary: 'red',
            secondary: '#FFFAEE',
          },
          position: 'bottom-right'
        })
      });

    return () => {
      mounted = false;
    }
  }, [category]);

  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }

  return (
    <>
      <Helmet>
        <title>Box Rental Packages | BlueBox</title>
        <meta name="description" content="We provide reusable moving boxes and supplies with free delivery and pickup in the San Francisco Bay Area. Check out our packages for your home or office" />
        <meta name="keywords" content="box rental packages, box rental packages San francisco, box rental packages Oakland, box rental packages San Jose" />
      </Helmet>
      <ZipCode
        rental={rental}
        showModal={openZipModal}
        hideModal={true}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        showHideHeader={props.showHideHeader}
        setShowHideHeader={props.setShowHideHeader}
        showHideFooter={props.showHideFooter}
        setshowHideFooter={props.setshowHideFooter}
        showHideinnerFooter={props.showHideinnerFooter}
        setshowHideinnerFooter={props.setshowHideinnerFooter}
        storeStatus={props.storeStatus}
        storeStatusMessage={props.storeStatusMessage}
      />
      {/* hero */}
      {/* {product} */}
      <section className="hero pricing position-relative">
        <div className="container">
          <div className="text-center">
            <h1
              className="text-white aos-init aos-animate"
              >
              <span className="font-weight-bold"> Box Rental Packages</span>
            </h1>
            <div className="col-md-12">
              <button
                className={`rounded-right ${category === 'Home' ? 'active' : ''} homebtn`}
                id="homebtn"
                value="Home"
                onClick={() => setnewcategory('Home')}>
                HOME
              </button>
              <button
                className={`rounded-left ${category === 'Office' ? 'active' : ''} officebtn`}
                id="officebtn"
                value="Office"
                onClick={() => setnewcategory('Office')}>
                OFFICE
              </button>
            </div>
          </div>
        </div>
      </section>
       <div className="free bg-primary py-3">
        <div className="container">
          <h2
            className="mb-0 text-center text-white"
            >
            BEST PRICES, GUARANTEED!
          </h2>
        </div>
      </div>
      {/* hero */}
      {/* pricing  */}
      <section className="pricing py-5">
        <div className="container-fluid">
          <div className="row">
            {product.results?.map((obj) => {
              // console.log(obj);
              return (
                <div
                  key={obj.id}
                  className="col-lg-2 pricing_packages pl-0 pr-0 d-flex aos-init aos-animate"

                >
                  <div className="card mr-2 ml-2 mb-2" style={{ marginBottom: "2%" }}>
                    <div className="card-header bg-danger">
                      <div className="text-center">
                        <h3 className="text-white" style={{ fontSize: '1.5em' }}>{obj.title}</h3>
                      </div>
                    </div>

                    {/* src={resolveImagePath(obj.title)} */}
                    <div className="text-center pt-3 pb-3">
                      {/* <img src={"img/PackageImages/" + obj.title + ".png"} className="img-fluid" /> */}
                      <img src={obj.description_image} className="img-fluid" />
                    </div>

                    <div className="card-body pb-0">
                      <div className="text-center">
                       {category === 'Home' ? (
        <>
          <h2>
            <span className="top fs-14 crs">$</span>
            {obj.price}
            <span className="middle fs-14">{/* / {obj.period} */}</span><br></br>
          </h2>
                            <h4>First Week</h4>
                            <h4>
                              <span className="middle fs-14">Additional weeks: ${obj.additional_weeks}</span>
                              </h4>

        </>
      ) : (
        <>
          <h2>
            <span className="top fs-14 crs">$</span>
            {obj.price}
            <span className="middle fs-14">{/* / {obj.period} */}</span><br></br>
          </h2>
          <h4>First Week</h4>
        </>
      )}
                        <div className="mt-3">
                          <div className="text-left ml-4 mr-3">
                            {obj.description.map((desc, index) => {
                              return (
                                  <p className="mb-0 fs-14" key={index}>
                                    <i className="fas fa-check color-primary"></i>{" "}
                                    {desc}
                                  </p>
                              );
                            })}
                            <p className="mb-0 fs-14">
                              <i className="fas fa-check color-primary"></i>{" "}
                              Free Recycling of Used Packing Materials {" "}
                              <Popover  content={content}>
                                <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                              </Popover>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='pkg-card-footer'>

                      <div className="text-center mt-3 orderbtn">
                        <button
                          className="btn btn-danger br-5 fs-16   border-0  fw-500"
                          style={{ margintop: '55px', lineHeight: "27px", width: "90%" }}
                          onClick={() => {
                            setRental([obj.id, obj.period]);
                            showZipModal();
                            setToggleModal(true);
                          }}>
                          Order Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              );
            })}

            {/* <div className="col-lg col-md-4 col-sm-6 p-2" data-aos="fade-right" data-aos-delay="1200" data-aos-duration="1000">
                            <div className="card">
                                <div className="card-header bg-primary text-center">
                                    <p className="text-white m-0">5 BEDROOM</p>
                                </div>
                                <div className="card-body-main">
                                    <div className="text-center">
                                        <h2>$309</h2>
                                        <p className="fs-14 mb-0">First week</p>
                                        <p className="fs-14">Additional Weeks: $80</p>
                                        <p className="border-top border-bottom">100 Bluebox Boxes </p>

                                    </div>
                                    <table className="table-pricing">
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    80 Large Boxes
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    10 Medium Boxes
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    10 Extra Large Boxes
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    2 Moving Dolly
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    100 Box Labels
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    Free Delivery & Pickup
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0">
                                                    Rec. for 1000-1500 sqft.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">
                                                <i className="fa fa-check"></i>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-primary mb-0 fw-700">
                                                    No Tax! We'll Cover It!
                                                </p>
                                            </td>
                                        </tr>
                                    </table>

                                    <div className="text-center mt-3">
                                        <button className="btn btn-dark" style={{ margintop: "55px" }} onClick={() => showZipModal()}>Oreder Now</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
      {/* pricing */}
      {/* free delivery */}
      <div className="free bg-primary py-3">
        <div className="container">
          <h2
            className="mb-0 text-center text-white"
            >
            FREE DELIVERY AND PICK UP ON ALL HOME AND OFFICE PACKAGES!
          </h2>
        </div>
      </div>
      {/* <!-- free delivery  */}
      {/* gallery  */}
      <section className="gallery py-4">
        <div className="container">
          <div className="cardbox">
            <div className="row">
              <div className="col-md-12">
                <img src="img/new-boxes-desktop2.png" />
              </div>
              <div id="mydiv">
                <img src="img/new-boxes-mobile1.png" className="image_mobile" alt="" />
                <img src="img/new-boxes-mobile2.png" className="image_mobile" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* gallery Slider  */}
      <section
        className="hero move-location position-relative location custom ">
        <div className="container">
          <div id="locations-content" className="ng-scope">
            <div
              className="row"
              style={{
                jstifyContent: 'center'
              }}>
              <div className="columns small-12 large-10 large-offset-1 end">
                <div className="order-box locationform">
                  {/* <h2><img width="32" src="img/icon-truck.png" data-interchange="[/assets/img/icon-truck@2x.png, (retina)]" /> Check If We Service Your Area</h2> */}

                  <div className="">
                    <div className="">
                      <div className="modal-header">
                        <img src="img/logo.png" className="img-fluid" alt="" />
                        <h5>Where are you moving?</h5>
                        {/* <button type="button" className="close" onClick={props.hideModal}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button> */}
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                          <div className="col-md-6 p-2">
                            Zip code of your current address?
                            <input
                              type="text"
                              id="ship-address"
                              className="form-control"
                              placeholder="Enter Zip code"
                              name="pickup_zipcode"
                              value={values.pickup_zipcode}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="col-md-6 p-2">
                            Zip code of your new address?
                            <input
                              type="text"
                              id="pickup-address"
                              className="form-control"
                              placeholder="Enter Zip code"
                              name="delivery_zipcode"
                              value={values.delivery_zipcode}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="col-12 text-right">
                            <a
                              href="box-package.html"
                              className="mt-4 btn btn-dark"
                              onClick={(e) => handleZipCode(e)}>
                              Continue
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  );
}

Index.propTypes = {
  showFooteroffice: PropTypes.any,
  setFooteroffice: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  showHideinnerFooter: PropTypes.any,
  showHideHeader: PropTypes.any,
  setShowHideHeader: PropTypes.any,
  showHideFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  setshowZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
