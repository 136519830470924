import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Image, Modal, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BoxImg from '../../../../../assets/images/box2.png';
import FreeImg from '../../../../../assets/images/free_delivery_circle.png';
import './index.css';

const TermsServiceModal = ({ visible, onClose, onTermsAccept }) => {
  const [termslist, setTermsList] = useState([]);
  const [content, setContent] = useState('');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const navigate = useNavigate();
  const { customer } = useSelector((state) => state.storage);

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    setTermsList(Array.from(tempElement.getElementsByTagName('li')).map((li) => li.innerText));
  }, [content]);

  const onAgreedTerms = (e) => {
    const isChecked = e.target.checked;
    setAgreedTerms(isChecked);
    console.log("Terms modal checkbox changed:", isChecked);
    
    if (isChecked) {
        console.log("About to call onTermsAccept function");
      setTimeout(() => {
        if (typeof onTermsAccept === 'function') {
          onTermsAccept(true);
        }
        onClose(); 
      }, 300);
    }
  };

  const handleTermsConfirm = () => {
    if (agreedTerms) {
      onTermsAccept(true);
      onClose();
    } else {
      message.error('Please agree to the terms and conditions before continuing');
    }
  };

  return (
    <Modal
      title={null}
      open={visible}
      onCancel={onClose}
      width={550}
      className="terms-service-modal"
      bodyStyle={{ 
        padding: 0, 
        height: 'auto', 
        maxHeight: '80vh',
        backgroundColor: '#f5f8fa'
      }}
      style={{ top: '10vh' }}
    >
      {/* Streamlined Header */}
      <div className="hiw-header">
        <div className="d-flex justify-content-center">
          <div className="header-content">
            <p className="text-white text-sub-header mb-0">Welcome to</p>
            <p className="text-white text-header mb-0">BlueBox storage on demand!</p>
          </div>
          <div className="header-image">
            <Image
              className="main-image"
              src={BoxImg}
              preview={false}
              alt="storage_header_image"
            />
          </div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div 
        className="modal-content-container"
        style={{ 
          overflowY: 'auto',
          maxHeight: 'calc(70vh - 150px)',
          padding: '15px'
        }}
      >
         {/* How It Works Section */}
         <div className="hiw-steps-content mb-3">
          <p className="mb-0 color-primary hiw-steps-header fw-600" style={{ fontSize: '18px', color: '#0066cc' }}>How it works</p>
          <p className="s-color-black hiw-steps-sub-header fw-400" style={{ fontSize: '14px', marginBottom: '15px' }}>
            Gone are the days of renting a storage unit and lugging things back and forth. We will do that for you.
          </p>
        </div>

        {/* Steps */}
        <div className="hiw-timeline" style={{ marginBottom: '20px' }}>
          <div className="timeline-item d-flex align-items-start mb-3">
            <div className="icon-container" style={{ marginRight: '15px' }}>
              <div style={{ border: '1px solid #ddd', borderRadius: '50%', padding: '5px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Image src={FreeImg} preview={false} alt="delivery" width={30} />
              </div>
            </div>
            <div className="step-content">
              <p className="mb-0" style={{ fontSize: '16px', color: '#0066cc', fontWeight: '600' }}>Step 1: Request Storage Boxes</p>
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>We will provide free delivery of our clean, plastic storage boxes right to your door.</p>
            </div>
          </div>

          <div className="timeline-item d-flex align-items-start mb-3">
            <div className="icon-container" style={{ marginRight: '15px' }}>
              <div style={{ border: '1px solid #ddd', borderRadius: '50%', padding: '5px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span role="img" aria-label="box" style={{ fontSize: '20px' }}>📦</span>
              </div>
            </div>
            <div className="step-content">
              <p className="mb-0" style={{ fontSize: '16px', color: '#0066cc', fontWeight: '600' }}>Step 2: Pack Your Stuff</p>
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>Pack your items into the boxes. Think of us as an extension of your home or office.</p>
            </div>
          </div>

          <div className="timeline-item d-flex align-items-start mb-3">
            <div className="icon-container" style={{ marginRight: '15px' }}>
              <div style={{ border: '1px solid #ddd', borderRadius: '50%', padding: '5px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span role="img" aria-label="calendar" style={{ fontSize: '20px' }}>📅</span>
              </div>
            </div>
            <div className="step-content">
              <p className="mb-0" style={{ fontSize: '16px', color: '#0066cc', fontWeight: '600' }}>Step 3: Schedule a Pick-Up</p>
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>We will pick up your boxes and our driver will take an overhead photo of each open box for you to inventory once uploaded to your account.</p>
            </div>
          </div>

          <div className="timeline-item d-flex align-items-start">
            <div className="icon-container" style={{ marginRight: '15px' }}>
              <div style={{ border: '1px solid #ddd', borderRadius: '50%', padding: '5px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span role="img" aria-label="delivery" style={{ fontSize: '20px' }}>🚚</span>
              </div>
            </div>
            <div className="step-content">
              <p className="mb-0" style={{ fontSize: '16px', color: '#0066cc', fontWeight: '600' }}>Step 4: Deliver</p>
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>Login to your online account to request a box delivery, and get your stuff back when you need it.</p>
            </div>
          </div>
        </div>

        {/* Storage Policy */}
        <div className="policy-container" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '15px', backgroundColor: 'white', marginBottom: '20px' }}>
          <p style={{ fontSize: '14px', margin: '0 0 5px 0' }}>Storage boxes are $12/box/month.</p>
          <p style={{ fontSize: '14px', margin: '0 0 5px 0' }}>There is no minimum number of boxes you can store with us—you can store only one if you choose. However, there is a three month storage minimum.</p>
          <p style={{ fontSize: '14px', margin: '0 0 5px 0' }}>You can cancel anytime within the three month period, but you will be charged upfront for the first three months of storage then month-to-month starting month four. Your card will be charged monthly (every 30 days) at the beginning of the fourth month and so on as long as you continue storage service.</p>
          <p style={{ fontSize: '14px', margin: '0' }}>If you would like to have any items returned, just login to your online account and request the box you would like delivered. Please note, for security purposes, we will only return items to the address on file.</p>
        </div>

        {/* Agreement */}
        <div className="agreement-section">
          <Form layout="vertical" autoComplete="off" initialValues={{ agree: false }}>
            <Form.Item
              name="agree"
              rules={[{ required: true, message: 'Please agree to terms and conditions' }]}
              noStyle
            >
              <Checkbox className="agree-lable" checked={agreedTerms} onChange={onAgreedTerms} style={{ fontSize: '14px' }}>
                I agree to{' '}
                <span style={{ color: '#0066cc', textDecoration: 'none' }}>BlueBox terms</span> of service
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

TermsServiceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTermsAccept: PropTypes.func.isRequired
};

export default TermsServiceModal;