import { ArrowLeftOutlined, ArrowRightOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Spin,
  Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '../../../../assets/images/boxfinal.png';
import moment from 'moment';
import PropTypes from 'prop-types';
import GoogleAutoComplete from 'react-google-autocomplete';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import './index.css';
import { setStepCount, setDeliveryDetails, setExtraWork, setStorageStepCount } from '../../store/actionCreators';
import { useDispatch, useSelector } from 'react-redux';

import { validateStorageZipCode, getStorageBlockDays, getStorageBlockDates } from '../../../../data/API';
import { get } from 'lodash';

const content = (
  <div className="box-popover">
    <p>A flight of stairs is seven or more stairs/steps leading in any one given direction.
      If you’re unsure you’ll need stair service at time of checkout but end up requiring it, we will apply the appropriate charge(s) to the payment method used to place the order once delivery and/or pick up has been completed. </p>
  </div>
);
const DeliveryDetails = ({
  deliveryWindows,
  extraWork,
  tabref,
  isParentNextStepClicked,
  setParentNextStepClicked,
  stepRef,
  customerInfo
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const currentStep = useSelector((state) => state.storage.currentStorageStep);
  const { deliveryDetails } = useSelector((state) => state.storage.order);
  const pincode = useSelector((state) => state.storage.pincode);

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [timingOne, setTimingOne] = useState(null);
  const [timingTwo, setTimingTwo] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [deliveryAddressError, setDeliveryAddressError] = useState(null);
  const [isForFuture, setIsForFuture] = useState(false);
  const [disableDays, setDisableDays] = useState([]);
  const [unavaliableDates, setUnavaliableDates] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: tabref?.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (pincode && Object.keys(deliveryDetails).length == 0) {
      form.setFieldsValue({
        zipCode: pincode ? pincode : ''
      });
    }
  }, [pincode]);

  useEffect(() => {
    if (deliveryDetails) {
      form.setFieldsValue({
        delivery_date: deliveryDetails?.delivery_date ? dayjs(deliveryDetails?.delivery_date) : '',
        delivery_window_1_id: deliveryDetails?.delivery_window_1_id
          ? deliveryDetails?.delivery_window_1_id
          : '',
        delivery_window_2_id: deliveryDetails?.delivery_window_2_id
          ? deliveryDetails?.delivery_window_2_id
          : '',
        apt_number: deliveryDetails?.apt_number ? deliveryDetails?.apt_number : '',
        extra_work_id: deliveryDetails?.extra_work_id ? deliveryDetails?.extra_work_id : '',
        description: deliveryDetails?.description ? deliveryDetails?.description : '',
        zipCode: pincode ? pincode : deliveryDetails?.zipCode ? deliveryDetails?.zipCode : ''
      });
    }

    if (deliveryDetails?.delivery_address) {
      setDeliveryAddress(deliveryDetails?.delivery_address);
    }
  }, [deliveryDetails]);

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 2) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          onSubmit();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  useEffect(() => {
    const storedState = localStorage.getItem('storageState');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      
      // Restore delivery details if they exist
      if (parsedState.order?.deliveryDetails) {
        const deliveryData = parsedState.order.deliveryDetails;
        
        form.setFieldsValue({
          delivery_date: deliveryData.delivery_date ? dayjs(deliveryData.delivery_date) : null,
          delivery_window_1_id: deliveryData.delivery_window_1_id,
          delivery_window_2_id: deliveryData.delivery_window_2_id,
          delivery_address: deliveryData.delivery_address,
          apt_number: deliveryData.apt_number,
          extra_work_id: deliveryData.extra_work_id,
          description: deliveryData.description
        });
        
        setDeliveryAddress(deliveryData.delivery_address);
        dispatch(setDeliveryDetails(deliveryData));
      }
    }
  }, []);

  const nextStep = () => {
    dispatch(setStorageStepCount(currentStep + 1));
  };

  const prevStep = () => {
    dispatch(setStorageStepCount(currentStep - 1));
  };

  const handleTimingOneChange = (evt) => {
    setTimingOne(evt);

    if (evt == timingTwo) {
      setTimingTwo(null);
    }
  };

  const handleTimingTwoChange = (evt) => {
    setTimingTwo(evt);
  };

   const blockedDays = () => {
      getStorageBlockDays().then((res) => {
        const blockedDaysArray = res?.data?.results?.map((obj) => obj.day) || [];
        setDisableDays(blockedDaysArray);
      });
    };
    
    useEffect(() => {
      blockedDays();
    }, []);

    const fetchUnavaliableDates = async () => {
      try {
        const response = await getStorageBlockDates();
        if (response?.data?.results) {
          setUnavaliableDates(response.data.results);
        }
      } catch (err) {
        console.error('Error fetching unavailable dates:', err);
      }
    };
    
    useEffect(() => {
      fetchUnavaliableDates();
    }, []);

    const disableDate = (current) => {
      const todayDate = moment();
      
      return (
        todayDate >= current.$d ||
        moment(todayDate).add(1, 'days').format('MM-DD-YYYY') === moment(current.$d).format('MM-DD-YYYY') ||
        // Check for blocked dates from admin dashboard
        (unavaliableDates && unavaliableDates.some(date => 
          moment(date.date).format('YYYY-MM-DD') === moment(current.$d).format('YYYY-MM-DD')
        )) ||
        // Check for blocked days
        disableDays.includes(moment(current.$d).format('dddd'))
      );
    };

  const onAddresschange = (e) => {
    if (deliveryAddress && e.target.value == 0) {
      setDeliveryAddress('');
      setDeliveryAddressError('Please Select Delivery Address');
    }
  };

  const setAddress = async (place) => {
    console.log(place);

    let postal, country, city, state;
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes('postal_code')) postal = address_element.long_name;
      if (address_element.types.includes('country')) country = address_element.short_name;
      if (address_element.types.includes('locality')) city = address_element.long_name;
      if (address_element.types.includes('administrative_area_level_1'))
        state = address_element.long_name;
    });

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    let zipCodeRes = await validateStorageZipCode({ deliveryZipCode: postal ? postal : '781006' });

    if (zipCodeRes?.data?.success && zipCodeRes?.data?.data?.serviceExist) {
      setZipCodeError(null);
      setDeliveryAddress(place?.formatted_address);
    } else {
      setZipCodeError('Service not available in this area.');
    }
  };

  const onSubmit = async () => {
    // setIsFormSubmitted(true)

    if (!deliveryAddress) {
      setDeliveryAddressError('Please select delivery address');
    }

    let formValues = await form.validateFields();

    if (formValues?.errorFields?.length > 0) {
      setIsFormSubmitted(false);
      return;
    }

    if (zipCodeError) {
      setIsFormSubmitted(false);
      return;
    }

    if (!deliveryAddress) {
      setIsFormSubmitted(false);
      setDeliveryAddressError('Please select delivery address');
      return;
    }

    let data = {
      delivery_date: moment(formValues.delivery_date?.$d).format('YYYY-MM-DD'),
      delivery_window_1_id: formValues?.delivery_window_1_id,
      delivery_window_2_id: formValues?.delivery_window_2_id,
      delivery_address: deliveryAddress,
      extra_work_id: formValues?.extra_work_id,
      zipCode: formValues?.zipCode,
      isForFuture: isForFuture
    };

    if (formValues?.apt_number) {
      data['apt_number'] = formValues.apt_number;
    }
    if (formValues?.description) {
      data['description'] = formValues.description;
    }

    dispatch(setDeliveryDetails(data));
    dispatch(setStorageStepCount(currentStep + 1));
  };

  const onStairsSelected = async (data) => {
    dispatch(setExtraWork(data));
  };

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 2: Delivery Details
        </h2>
        <Card bordered={false} className="w-100 px-3 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_date"
                    label="Delivery Date"
                    rules={[{ required: true, message: 'Please choose a delivery date' }]}>
                    <DatePicker
                      className="w-100"
                      size="large"
                      format="MM-DD-YYYY"
                      disabledDate={disableDate}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_1_id"
                    label="Delivery Window 1"
                    rules={[{ required: true, message: 'Please choose a delivery window 1' }]}>
                    <Select
                      size="large"
                      placeholder="Select a time slot"
                      value={timingOne}
                      onChange={handleTimingOneChange}>
                      {deliveryWindows?.map((options, index) => {
                        return (
                          <Select.Option
                            key={`timing_one_${index}`}
                            hidden={options?.id == timingTwo}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="delivery_window_2_id"
                    label="Delivery Window 2"
                    rules={[{ required: true, message: 'Please choose a delivery window 2' }]}>
                    <Select
                      size="large"
                      placeholder="Select a time slot"
                      onChange={handleTimingTwoChange}
                      value={timingTwo}
                      disabled={!timingOne}>
                      {deliveryWindows?.map((options, index) => {
                        return (
                          <Select.Option
                            hidden={options?.id == timingOne}
                            key={`timing_two_${index}`}
                            value={options?.id}>
                            {options?.start_time} - {options?.end_time}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className="mandatory-field"
                    name="delivery_address"
                    label="Delivery Address">
                    <GoogleAutoComplete
                      className={
                        deliveryAddressError && !deliveryAddress
                          ? 'form-control delivery-address-field-error '
                          : 'form-control'
                      }
                      apiKey={apiKey}
                      onPlaceSelected={(place) => {
                        setAddress(place);
                      }}
                      onChange={(e) => {
                        onAddresschange(e);
                      }}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                      defaultValue={deliveryDetails?.delivery_address || deliveryAddress}
                    />
                    <p className="mb-0 ant-form-item-explain-error">
                      {zipCodeError ? zipCodeError : null}
                    </p>
                    <p className="mb-0 ant-form-item-explain-error">
                      {deliveryAddressError && !deliveryAddress ? deliveryAddressError : ''}
                    </p>
                    <p className="mb-0 text-success">
                      {!zipCodeError && deliveryAddress ? 'Services available in this area' : null}
                    </p>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="apt_number" label="Apt." rules={[{ required: false }]}>
                    {/* <Input size="large" placeholder="Apt" /> */}
                    <InputNumber
                      className="w-100"
                      placeholder="Apt."
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="extra_work_id"
                    className='tool-tip'
                    label={
                      <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "10px" }}>
                        <span>Do you have stairs or an elevator? </span>
                        <Popover content={content}>
                          <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                        </Popover>
                      </div>}
                    rules={[{ required: true, message: 'Please select an option from below' }]}>
                    <Select size="large" onChange={onStairsSelected}>
                      {extraWork?.map((obj, index) => {
                        return (
                          <Select.Option
                            className="mobile-wide-option"
                            key={`extarwork_${index}`}
                            value={obj?.id}>{`${obj.title} ($${obj.price})`}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                </Col>
                {/* <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="zipCode" label="ZipCode" rules={[{ required: false }]}>
                    <Input size="large" placeholder="Zip Code" />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="description"
                    label="Is there anything else we should know?"
                    rules={[{ required: false }]}>
                    <TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}></Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  {/* <Form.Item name="isForFuture" checked={false} noStyle>
                    <Checkbox className='agree-lable' onChange={(e) => { setIsForFuture(e.target.checked) }}>Save my details  for future orders</Checkbox>
                  </Form.Item> */}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            size={'large'}
            onClick={prevStep}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            loading={isFormSubmitted}
            onClick={onSubmit}
            size={'large'}>
            {!isFormSubmitted ? 'Next Step' : 'Submitting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;

DeliveryDetails.propTypes = {
  deliveryWindows: PropTypes.any,
  extraWork: PropTypes.any,
  tabref: PropTypes.any,
  stepRef: PropTypes.any,
  isParentNextStepClicked: PropTypes.any,
  setParentNextStepClicked: PropTypes.any,
  customerInfo: PropTypes.any
};