import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { checkZipCode, validateStorageZipCode } from '../../data/API';
import SimpleReactValidator from 'simple-react-validator';
import Mainloader from '../BoxPackages/components/Mainloader';
import PropTypes from 'prop-types';
import { setRentalId, setRentalPeriod } from '../BoxPackages/store/actionCreators';
import { setPincode } from '../StorageBox/store/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import RenderIf from '../../Utility/RenderIf';
import { Spinner } from 'react-bootstrap';
import { Form, Select, Input, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { getToken } from '../../utils/token';

export default function ZipCode(props) {
    const navigate = useNavigate();
    const [, forceUpdate] = useState();
    const validator = useRef(new SimpleReactValidator());
    const [mainloader, setMainloader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMsg, setMsg] = useState('');
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { user } = useSelector(state => state.auth);
    const loginToken = getToken();

    const { service_type } = props;

    const [values, setValues] = useState({
        service_type: service_type ? service_type : "RENT_MOVING_BOXES",
        pickup_zipcode: '',
        delivery_zipcode: ''
    });

    const handleChange = (event) => {
        setMsg('')
        setValues({ ...values, [event.target.name]: event.target.value });

        const item = {
            value: true,
            expiry: new Date().getTime()
        };
        localStorage.setItem('zipcode', JSON.stringify(item));
    };

    const handleZipCode = async (e) => {

        if (values?.service_type && values.service_type == "STORAGE_ON_DEMAND") {

            if (validator.current.allValid()) {
                setSubmitLoader(true);

                let payload = {
                    deliveryZipCode: values.delivery_zipcode
                };

                try {
                    let validateRes = await validateStorageZipCode(payload);

                    if (validateRes?.data?.data?.serviceExist) {
                        if (typeof props.hideModal === 'function') {
                            props.hideModal();
                        }

                        dispatch(setPincode(values.delivery_zipcode));
                        setSubmitLoader(false);
                        navigate('/storage-box/buying-box');

                        // if (user?.name) {
                        // if (loginToken) {
                        //     navigate('/storage-box/buying-box');
                        // } else {
                        //     navigate('/storage-box/login');
                        // }
                    } else {
                        setSubmitLoader(false);

                        setError(true);
                        setMsg("Service not available in this area.");
                    }
                } catch (e) {
                    setSubmitLoader(false);
                    setError(true);
                    setMsg("Something Went Wrong Try Again");
                }
            } else {
                validator.current.showMessages();
                forceUpdate(1);
                return false;
            }


        } else {
            setMainloader(true);
            if (validator.current.allValid()) {
                setSubmitLoader(true);

                e.preventDefault();

                let obj = {
                    pickup_zipcode: values.pickup_zipcode,
                    delivery_zipcode: values.delivery_zipcode
                };
                checkZipCode(obj)
                    .then((res) => {
                        if (res?.data?.success) {
                            if (typeof props.hideModal === 'function') {
                                props.hideModal();
                            }
                            if (props.rental) {
                                dispatch(setRentalId(props.rental[0]));
                                dispatch(setRentalPeriod(props.rental[1]));
                            }
                            setSubmitLoader(false);

                            navigate('/packages');
                        } else {
                            setSubmitLoader(false);
                            setError(true);
                            setMsg(res?.data?.message);
                        }
                    })
                    .catch((e) => {
                        setSubmitLoader(false);
                        console.log(e);
                    });
            } else {
                validator.current.showMessages();
                forceUpdate(1);
                return false;
            }
        }
    };
    // console.log(props.toggleModalFunction)
    return (
        <>
            {mainloader ? <Mainloader /> : ' '}
            {props.toggleModal && (
                <Modal
                    show={props.showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={props.hideModal}
                    dialogClassName="common-zip-modal"
                >
                    <div className="position-absolute z-3">
                        <div
                            className=""
                            style={{
                                position: 'relative',
                                backgroundColor: 'white',
                                borderRadius: '4px'
                            }}>
                            <div className="modal-header d-flex justify-content-center align-items-center">
                                <h5 className='mb-0 fw-600'>
                                    {props.storeStatus === "open" ? "How can we help?" : <></>}
                                </h5>
                                <button
                                    type="button"
                                    style={{ position: 'absolute', right: '14px' }}
                                    className="close"
                                    onClick={() => {
                                        props.toggleModalFunction(false);
                                    }}>

                                    <span aria-hidden="true" className="close" data-dismiss="modal">
                                        &times;
                                    </span>
                                </button>


                            </div>
                            <div className="modal-body pt-1">
                                <RenderIf isTrue={!props.storeStatus || !props.storeStatusMessage}>
                                    <div className="row d-flex justify-content-center align-center">
                                        <Spinner animation="border" size={'lg'} role="status" variant="primary">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={!!props.storeStatus && !!props.storeStatusMessage}>
                                    <RenderIf isTrue={props.storeStatus === "close"}>
                                        <div className="row d-flex justify-content-center align-center text-center">
                                            <h1>
                                                {props.storeStatusMessage}
                                            </h1>
                                        </div>
                                    </RenderIf>
                                    <RenderIf isTrue={props.storeStatus === "open"}>
                                        <div className="row content-row">
                                            {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}

                                            <Form
                                                form={form}
                                                layout="vertical"
                                                autoComplete="off"
                                                style={{ position: "relative", zIndex: 0, width: "100%" }}
                                            >
                                                <Row>
                                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="p-2">
                                                        <Form.Item
                                                            label="Service"
                                                            name="service_type"
                                                        >
                                                            <Select
                                                                size="large" style={{ width: "100%" }}
                                                                placeholder="Select an option"
                                                                onChange={(e) => handleChange({ target: { name: "service_type", value: e } })}
                                                                value={values.service_type}
                                                            >
                                                                <Select.Option value={"RENT_MOVING_BOXES"}>Rent Moving Boxes</Select.Option>
                                                                <Select.Option value={"STORAGE_ON_DEMAND"}>Storage On-Demand</Select.Option>
                                                            </Select>
                                                            {validator.current.message(
                                                                'service_type',
                                                                values.service_type,
                                                                'required',
                                                                { className: 'text-danger' }
                                                            )}
                                                        </Form.Item>
                                                    </Col>

                                                    <div className="col-md-12 p-2 pt-3" hidden={values.service_type == "STORAGE_ON_DEMAND"}>
                                                        Zip code of your current address?
                                                        <input
                                                            type="text"
                                                            id="ship-address"
                                                            className="form-control mt-2"
                                                            placeholder="Enter Zip code"
                                                            name="pickup_zipcode"
                                                            value={values.pickup_zipcode}
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {validator.current.message(
                                                            'pickup_zipcode',
                                                            values.pickup_zipcode,
                                                            values.service_type == "RENT_MOVING_BOXES" ? 'required' : "string",
                                                            { className: 'text-danger' }
                                                        )}
                                                    </div>
                                                    <div className="col-md-12 p-2 pt-3" >
                                                        Zip code of your new address?
                                                        <input
                                                            type="text"
                                                            className="form-control mt-2"
                                                            placeholder="Enter Zip code"
                                                            name="delivery_zipcode"
                                                            value={values.delivery_zipcode}
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {validator.current.message(
                                                            'delivery_zipcode',
                                                            values.delivery_zipcode,
                                                            'required',
                                                            { className: 'text-danger' }
                                                        )}
                                                    </div>
                                                    <div className="col-12 mt-3 pl-1 pr-1">
                                                        <div className="text-right mt-4 zipmodal">
                                                            <Button loading={submitLoader} className="btn continue-zip-code-btn d-flex justify-content-center align-items-center" style={{ height: "40px" }} onClick={(e) => handleZipCode(e)}>
                                                                Get started
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </Row>
                                            </Form>


                                        </div>
                                    </RenderIf>
                                </RenderIf>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
            }
        </>
    );
}

ZipCode.propTypes = {
    rental: PropTypes.array,
    showModal: PropTypes.any,
    hideModal: PropTypes.func,
    toggleModal: PropTypes.any,
    toggleModalFunction: PropTypes.any,
    showHideHeader: PropTypes.any,
    setShowHideHeader: PropTypes.func,
    showHideFooter: PropTypes.any,
    setshowHideFooter: PropTypes.func,
    showHideinnerFooter: PropTypes.any,
    setshowHideinnerFooter: PropTypes.func,
    storeStatus: PropTypes.any,
    storeStatusMessage: PropTypes.any,
    service_type: PropTypes.any
};
