import * as actionTypes from './constants';

const defaultStateValues = () => {
  return {
    currentStep: 0,
    currentStorageStep: 0,
    customer: {},
    order: {
      boxCount: 0,
      deliveryDetails: {},
      personalDetails: {},
      paymentDetails: {}
    },
    deliveryOrder: {
      requestedBoxes: [],
      deliveryDetails: {}
    },
    pincode: null,
    extraWorkId: null
  };
};

const defaultState = defaultStateValues();

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.RESET_REDUX_STORE:
      return defaultStateValues();

    case actionTypes.SET_STEP_COUNT:
      return { ...state, currentStep: action.data };

    case actionTypes.SET_STORAGE_STEP_COUNT:
      return { ...state, currentStorageStep: action.data };

    case actionTypes.SET_CUSTOMER:
      return { ...state, customer: action.data };

    case actionTypes.SET_BOX_COUNT:
      return { ...state, order: { ...state.order, boxCount: action.data } };

    case actionTypes.SET_DELIVERY_DETAILS:
      return { ...state, order: { ...state.order, deliveryDetails: action.data } };

    case actionTypes.SET_PERSONAL_DETAILS:
      return { ...state, order: { ...state.order, personalDetails: action.data } };

    case actionTypes.SET_SB_PINCODE:
      return { ...state, pincode: action.data };

    case actionTypes.SET_EXTRA_WORK_ID:
      return { ...state, extraWorkId: action.data };

    case actionTypes.SET_REQUESTED_BOXES:
      return { ...state, deliveryOrder: { ...state.deliveryOrder, requestedBoxes: action.data } };

    case actionTypes.SET_STORAGE_DELIVER_DETAILS:
      return { ...state, deliveryOrder: { ...state.deliveryOrder, deliveryDetails: action.data } };


    default:
      return state;
  }
};
